<template>
  <section>
    <swt-loading-css v-if="load" />

    <div id="cont-wrap">
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="site-wrap">
        <ul class="link-list">
          <a
            v-for="(site, index) in result"
            v-bind:key="index"
            v-bind:href="site.url"
            target="_blank"
            rel ="noopener"
          >
            <li class="link-space">
              <p class="link-name">{{ site.name }}</p>
              <p class="link-comment">{{ site.detail }}</p>
            </li>
          </a>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLoadingCss from "../components/SwtLoadingCss";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "AccessRecomme",
  components: {
    SwtLoadingCss,
  },
  data: function () {
    return {
      detail: `
お勧めサイトを活用することで収益UP！
                `,
      result: [],
      load: false,
    };
  },
  computed: {
    ...mapGetters({
      getData: "view/getData",
    }),
  },
  created() {
    const data = this.getData("recomme");
    if (data) {
      this.result = data;
    } else {
      this.getAccessRecomme();
    }
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    getAccessRecomme: function () {
      if (this.load !== false) return;
      const url = process.env.VUE_APP_API_URL + "accessRecomme/get";
      this.load = true;
      this.axios
        .get(url)
        .then((response) => {
          this.load = false;
          if (response.data) {
            const data = response.data;
            data.forEach((site) => {
              this.result.push({
                name: site["name"],
                url: site["url"],
                detail: site["detail"],
              });
            });
            this.setData({ key: "recomme", value: this.result });
          }
        })
        .catch(() => {
          this.load = false;
        });
    },
    ...mapMutations({
      setData: "view/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>