<template>
  <section>
    <swt-logo class="login-logo" />
    <swt-loading-css v-if="isLoggingIn" />

    <div class="login-container">
      <div class="login-box">
        <p>
          <span style="color: red">{{ showError }}</span>
        </p>
        <p>
          <label for="login-id">
            <input
              type="text"
              id="login-id"
              class="login-id"
              placeholder="ログインID"
              v-bind:class="isError('login_id')"
              v-model="loginParm.login_id"
              v-on:keydown.enter="submit"
            />
          </label>
        </p>
        <p>
          <label for="login-pass">
            <input
              type="password"
              id="login-pass"
              class="login-pass"
              placeholder="パスワード"
              v-bind:class="isError('password')"
              v-model="loginParm.password"
              v-on:keydown.enter="submit"
            />
          </label>
        </p>
        <button
          type="button"
          class="login-btn"
          v-on:click="login"
        >ログイン</button>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLogo from "../components/SwtLogo";
import SwtLoadingCss from "../components/SwtLoadingCss";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "LoginPage",
  components: {
    SwtLogo,
    SwtLoadingCss,
  },
  data() {
    return {
      loginParm: {
        login_id: "",
        password: "",
      },
      isLoggingIn: false,
    };
  },
  created() {
    this.clearData();
  },
  methods: {
    submit: function (e) {
      // 通常のエンター以外は無視(日本語変換中など)
      // keydown.enter: e.keyCode === 229
      if (e.keyCode !== 13) return;
      this.login();
    },
    login() {
      if (this.isLoggingIn) return;
      this.isLoggingIn = true;
      this.userLogin(this.loginParm);
    },
    ...mapActions({
      userLogin: "auth/login",
    }),
    ...mapMutations({
      clearData: "view/clearData",
    }),
  },
  computed: {
    isError() {
      return (id) => {
        let error = false;
        if (this.error !== null && this.error.errors !== undefined) {
          error = this.error.errors[id] !== undefined;
        }
        return {
          error: error,
        };
      };
    },
    showError() {
      if (this.error !== null) {
        return this.error.message;
      }
      return "";
    },
    ...mapGetters({
      error: "auth/getError",
    }),
  },
  watch: {
    showError: function (newVal) {
      if (newVal) this.isLoggingIn = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>