<template>
  <p v-bind:class="logoClass">
    <img
      v-bind:src="require('../assets/img/' + img)"
      v-bind:alt="text"
    />
  </p>
</template>

<script>
export default {
  name: "SwtLogo",
  props: {
    text: {
      type: String,
      default: "スマートリンクプレミアム",
    },
    img: {
      type: String,
      default: "logo-round.png",
    },
    logoClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
</style>