<template>
  <section>
    <div id="cont-wrap">
      <p
        class="comment-line"
        v-html="getParagraphText(detail)"
      ></p>

      <div class="flex-cont" v-for="(e, i) in getActiveEngine" v-bind:key="i">
        <swt-loading v-if="load[e]" />

        <div v-if="result.search[e].length > 0">
          <div class="browser-title">
            <img v-bind:src="require('../assets/img/' + e + '_logo.svg')" />
          </div>
          <ol>
            <li v-for="(data, index) in result.search[e]" v-bind:key="index">
              <a v-bind:href="data.href" target="_blank">{{ data.text }}</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwtLoading from "../components/SwtLoading";
export default {
  name: "SearchHotkeyword",
  components: {
    SwtLoading,
  },
  data: function () {
    return {
      detail: `
グーグルで検索数が多いキーワードをリアルタイムで表示！
表示されたキーワードを利用してみましょう！
                `,
      engine: [
        "google",
        // "yahoo",
        // "bing",
      ],
      result: {
        search: {
          google: [],
          // yahoo: [],
          // bing: [],
        },
        active: {
          google: true,
          // yahoo: true,
          // bing: true,
        },
      },
      load: {
        google: false,
        // yahoo: false,
        // bing: false,
      },
    };
  },
  created: function () {
    this.getSearch();
  },
  computed: {
    getActiveEngine: function () {
      return this.engine.filter((d) => {
        return (
          (this.result.search[d].length > 0 || this.load[d]) &&
          this.result.active[d] === true
        );
      });
    },
  },
  methods: {
    getParagraphText: function (text) {
      return text.trim().replace(/\r?\n/g, "<br/>");
    },
    getSearch: function () {
      this.engine.forEach((e) => {
        if (this.result.active[e] === false || this.load[e] !== false) return;
        const url = process.env.VUE_APP_API_URL + "trend/" + e;
        this.load[e] = true;
        this.result.search[e] = [];
        this.axios
          .get(url)
          .then((response) => {
            this.load[e] = false;
            if (response.data[0]) {
              response.data.forEach((node) => {
                const data = {
                  text: node.title,
                  href: node.href,
                  rank: node.rank,
                };
                if (node.href2 !== undefined && node.href2 !== "") {
                  data.href = node.href2;
                }
                this.result.search[e].push(data);
              });
            }
          })
          .catch(() => {
            this.load[e] = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>