import router from "../../router";

export default {
  namespaced: true,
  state: {
    data: {},
    navItems: [
      {
        name: "search_multi",
        display: "トレンドブログ",
        icon: "magnifying-glass",
      },
      {
        name: "search_hotkeyword",
        display: "トレンドワード",
        icon: "arrow-trend-up",
      },
      {
        name: "search_seo",
        display: "SEO",
        icon: "arrow-pointer",
      },
      {
        name: "access_send",
        display: "アクセス送付",
        icon: "square-up-right",
      },
      {
        name: "access_recomme",
        display: "お勧めサイト",
        icon: "desktop",
      },
      {
        name: "access_income",
        display: "特別サイト",
        icon: "money-bill-trend-up",
      },
    ],
  },
  // state, value / state, { payload }
  mutations: {
    setData: (state, { key, value }) => {
      // state.data[key] = value;
      state.data = {
        ...state.data,
        [key]: value,
      }
    },
    clearData: (state) => {
      state.data = {};
    },
  },
  // context, value / context, { payload }
  // context.(state, getters, rootState, rootGetters, commit, dispatch)
  actions: {},
  // state, getters, rootState, rootGetters
  // (state, getters, rootState, rootGetters) => (value)
  getters: {
    getData: (state) => (key) => {
      return state.data[key] ? state.data[key] : null;
    },
    getNavItem: (state) => state.navItems.filter((d) => router.currentRoute.name === d.name),
    getNavItems: (state) => state.navItems,
  },
};
