<template>
  <footer v-if="this.isLogin">
    <nav class="menu">
      <ul class="gmenu">
        <li class="gmenus">
          <div
            class="openbtn"
            v-bind:class="{ 'active': isActiveMenu }"
            v-on:click="toggleMenu()"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
        <nav
          id="g-nav"
          v-bind:class="{ 'panelactive': isActiveMenu }"
        >
          <div id="g-nav-list">
            <ul>
              <li v-for="(page, navIndex) in this.navItems" v-bind:key="navIndex">
                <router-link
                  v-on:click.native="toggleMenu()"
                  v-bind:to="{ name: page.name }"
                  ><font-awesome-icon v-bind:icon="page.icon" />{{ page.display }}</router-link
                >
              </li>
            </ul>
          </div>
        </nav>
      </ul>
    </nav>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SwtNav",
  data() {
    return {
      isActiveMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActiveMenu = !this.isActiveMenu
    },
    ...mapActions({
      userLogout: "auth/logout",
    }),
  },
  computed: {
    navItems() {
      if (!this.isLogin) return []
      return this.getNavItems.filter((d) => d.name === "top" || this.userData["active_" + d.name])
    },
    ...mapGetters({
      isLogin: "auth/check",
      userData: "auth/getUser",
      getNavItems: "view/getNavItems",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>